/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData, uploadFile } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";
import Select from "react-select";

const CsvUploadModal = () => {

  const fileInputRef = useRef(null);

  const { t } = useTranslation(); //for translation

  const [file, setFile] = useState(null);
  const [uploadDocUrl, setUploadedDocUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isSavingData, setIsSavingData] = useState(false);
  const [uploadedDataCount, setUploadedDataCount] = useState("");

  const options = [
                  { value: 'AMB', label: 'AMB' },
                  { value: 'Funbrain', label: 'Funbrain' }
                ];

  const delimeteroptions = [
                  { value: 'comma', label: ' , comma ' },
                  { value: 'semicolon', label: ' ; semi-colon' }
                ];

  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState("");

  const token = localStorage.getItem("token");

  // file source option
  const [selectedOption, setSelectedOption] = useState(null);
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const [selectedDelimeterOption, setSelectedDelimeterOption] = useState({ value: 'semicolon', label: ' ; semi-colon' });
  const handleSelectDelimeterChange = (selectedOption) => {
    setSelectedDelimeterOption(selectedOption);
  };

  const handleUploadClick = () => {

    if (!file) {
      setError("Please select a file to upload.");
      return;
    }

    if(selectedOption==null){
      setError("Please select a source of your upload.");
      return;
    }

    setError("");
    docUploadHandler();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError(""); // Clear the error when a file is selected
  };

  const resetDefault = () =>{
    setFile(null);
    setUploadedDocUrl(null);
    setUploadedDataCount("");
    setError("");
  }

  //** Upload file in server which will return the path
  const docUploadHandler = async (e) => {
    try {
      setIsUploading(true);
      setIsSavingData(false);
      
      // reset dependent data to avoid duplicate
      setUploadedDocUrl("");

      let requestURL =
        url.API_BASEURL + url.API_FILE_UPLOAD + `?token=${token}`;

      console.log("req url", requestURL);

      const response = await uploadFile(requestURL, file);

      if (response.status) {
        console.log("file upload response", response);

        // set the uploaded url path
        setUploadedDocUrl(response.data.path);

        setIsUploading(false);

        // Reset the file input field
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }

        setFile(null)
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // send the uploaded file path to server to start fetching data
  const saveUploadedDocData = async () => {
    try {
      // show wait while we fetch and map the data from AI
      setIsSavingData(true);

      if (uploadDocUrl && uploadDocUrl.length > 5) {
        let postParams = {
          filepath: "public" + uploadDocUrl,
          source: selectedOption.value,
          delimeter: selectedDelimeterOption.value
        };

        let requestUrl =
          url.API_BASEURL + url.API_UPLOAD_CSV + `?token=${token}`;

        console.log("requestUrl", requestUrl);

        const response = await postData(requestUrl, postParams);

        console.log("bulk response data", response);

        setFile(null);

        setIsUploading(false);

        if (response.status) {
          setIsSavingData(false); // stop showing loading

          setUploadedDataCount(response.data)

          // let uploadModal = document.querySelector("#csvUploadModal");
          // let modal = bootstrap.Modal.getInstance(uploadModal);
          // modal.hide();

          //setAlertMessage(response.message);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //** use effect for send uploaded doc file url to server
  useEffect(() => {
    if (uploadDocUrl && uploadDocUrl.length > 5) {
      console.log("uploadDocUrl", uploadDocUrl);
      saveUploadedDocData();
    }
  }, [uploadDocUrl]);

  return (
    <div
      className="modal"
      id="csvUploadModal"
      tabIndex="-1"
      aria-labelledby="csvUploadModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0">
          <div className="modal-header border-0">
            <h3 className="text-secondary mb-0" id="csvUploadModalLabel">
              <span className="d-block">{t("Upload CSV File")}</span>
            </h3>
            <button
              type="button"
              className="btn-close p-2 bg-white shadow-none shadow-sm m-0 ms-auto"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => resetDefault()}
            ></button>
          </div>
          <div className="modal-body text-center p-5 pt-0">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group mb-4">
                <label htmlFor="intern"  style={{ textAlign: "left" }}>
                 1. {t("Choose a CSV file as downloaded from AMB")} * 
                </label>

                <input
                  type="file"
                  accept=".csv"
                  ref={fileInputRef}
                  className="form-control border-gray mb-3"
                  onChange={handleFileChange}
                />

                {/* add select option with 2 options, 1. AMB, 2. Funbrain */}
                <label htmlFor="inter" className="mt-3"  style={{ textAlign: "left" }}>
                 2. {t("Select source of your upload")} *
                </label>
                <Select
                  options={options}
                  value={selectedOption}
                  onChange={handleSelectChange}
                />

                {/* add select option with 2 separator options, 1. AMB, 2. Funbrain */}
                <label htmlFor="inter" className="mt-3"  style={{ textAlign: "left" }}>
                 3. {t("Select CSV delimeter , or ;")} *
                </label>
                <Select
                  options={delimeteroptions}
                  value={selectedDelimeterOption}
                  onChange={handleSelectDelimeterChange}
                />
                
                {error && <div className="text-danger mt-2">{error}</div>}

                {uploadedDataCount && <div className="text-primary mt-2">{uploadedDataCount} records uploaded</div>}
              



                <h6
                  className={`text text-primary mt-3 ${
                    isUploading ? "" : "d-none"
                  }`}
                >
                  <div className="spinner-border me-4" role="status">
                    <span className="sr-only"></span>
                  </div>
                  {t("File uploading in process")}
                  
                </h6>
                <h6
                  className={`text text-primary mt-3 ${
                    isSavingData ? "" : "d-none"
                  }`}
                >
                  1. {t("File Uploaded Successfully")}
                  
                  <br />

                  2. {t("Saving data. Please wait for while.")}
                
                  <div className="spinner-border me-4" role="status">
                    <span className="sr-only"></span>
                  </div>
                </h6>
              </div>

              <div className="action d-flex align-items-center justify-content-end gap-2">
                <button
                  type="button"
                  className="btn btn-outline-secondary text-gray"
                  data-bs-dismiss="modal"
                  onClick={() => resetDefault()}
                >
                  {t("Close")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleUploadClick()}
                >
                  {t("Upload")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CsvUploadModal;
